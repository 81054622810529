body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eeeeee;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
  .MuiSvgIcon-root {
    color: #e94b94;
  }
    background: none !important;
}

.MuiTable-root {
  position: sticky;
  bottom: 0;
  background: white;
}

.MuiTableCell-paddingNone {
  padding: 0;
  border: none !important;
}

#raw-group-bar {
  .MuiChip-deleteIcon {
    visibility: hidden;
  }
}

.orgchart.myChart > ul > li > ul li::before {
  border-top-color: #215a88;
}

.orgchart.myChart > ul > li > ul li .oc-node::before,
.orgchart.myChart ul li .oc-node:not(:only-child)::after {
  background-color: #215a88;
}

.orgchart.myChart .oc-node .position {
  box-sizing: border-box;
  background-color: #215a88;
  color: #fff;
  width: 130px;
  height: 65px;
  padding: 2px;
}

.orgchart.myChart .oc-node .fullname {
  box-sizing: border-box;
  color: #215a88;
  background-color: #fff;
  width: 130px;
  height: 35px;
  padding: 2px;
  border: 1px solid #215a88;
}

